// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-industry-detail-template-js": () => import("../src/templates/IndustryDetailTemplate.js" /* webpackChunkName: "component---src-templates-industry-detail-template-js" */),
  "component---src-templates-team-detail-template-js": () => import("../src/templates/TeamDetailTemplate.js" /* webpackChunkName: "component---src-templates-team-detail-template-js" */),
  "component---src-templates-insight-detail-template-js": () => import("../src/templates/InsightDetailTemplate.js" /* webpackChunkName: "component---src-templates-insight-detail-template-js" */),
  "component---src-templates-services-detail-template-js": () => import("../src/templates/ServicesDetailTemplate.js" /* webpackChunkName: "component---src-templates-services-detail-template-js" */),
  "component---src-templates-engagements-detail-template-js": () => import("../src/templates/EngagementsDetailTemplate.js" /* webpackChunkName: "component---src-templates-engagements-detail-template-js" */),
  "component---src-templates-assets-detail-template-js": () => import("../src/templates/AssetsDetailTemplate.js" /* webpackChunkName: "component---src-templates-assets-detail-template-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-assets-js": () => import("../src/pages/assets.js" /* webpackChunkName: "component---src-pages-assets-js" */),
  "component---src-pages-business-debt-solutions-js": () => import("../src/pages/business-debt-solutions.js" /* webpackChunkName: "component---src-pages-business-debt-solutions-js" */),
  "component---src-pages-contact-js": () => import("../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-engagements-js": () => import("../src/pages/engagements.js" /* webpackChunkName: "component---src-pages-engagements-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industries-js": () => import("../src/pages/industries.js" /* webpackChunkName: "component---src-pages-industries-js" */),
  "component---src-pages-insights-js": () => import("../src/pages/insights.js" /* webpackChunkName: "component---src-pages-insights-js" */),
  "component---src-pages-privacy-js": () => import("../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-services-js": () => import("../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-team-js": () => import("../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */)
}

